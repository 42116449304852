@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

.login-wrapper{
    background-image: linear-gradient( 255.9deg,  rgba(221,221,221,1) 12.6%, #bbb 91.3% );
    font-family: Arial, Helvetica, sans-serif;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-card{
    background-image: radial-gradient( circle 862px at 6% 18%,  rgba(21,219,149,1) 9.4%, #018d6a 83.6% );
    height: 70vh;
    width: 60%;
    display: flex;
    border-radius: 20px;
    box-shadow: 2px 2px 8px #3e3e3e;
}

.login-card-body{
    flex: 0.6;
    border-radius: 20px;
    background-color: #fff;
}

.login-card-background{
    flex: 0.4;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    flex-direction: column;
}

.login-card-background > h2{
    font-size: 30px;
    margin-bottom: 15px;
}

.login-card-background > hr{
    width: 10%;
    height: 2px;
    background-color: white;
    border: 0px;
    border-radius: 10px;
    margin-bottom: 15px;
}

.login-card-background > p{
    font-size: 14px;
    margin-bottom: 25px;
    line-height: 25px;
}

.login-card-background > button{
    padding: 10px;
    width: 40%;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 25px;
    cursor: pointer;
}

.login-card-body{
    padding: 20px;
    position: relative;
}

.login-card-body > h4{
    display: flex;
    align-items: center;
    color: #575757;
    margin-bottom: 30px;
    font-size: 16px;
}

.login-card-body > div >h2{
    color: #018d6a;    
    margin-bottom: 20px;
}

.login-card-body > div > hr{
    width:8%;
    height: 2px;
    background-color: #018d6a;
    border: 0px;
    border-radius: 10px;
    margin-bottom: 30px;
}

.form-check{
    margin-right: 5px;
}

.btn{
    padding: 10px;
    width: 30%;
    background-color: #018d6a;
    color: white;
    border: 0;
    border-radius: 25px;
    cursor: pointer;
    margin-top: 10px;
}

.form-control{
    height: 25px;
    border-radius: 6px;
    padding: 6px;
    font-size: 15px;
    border: 0.5px solid rgb(137, 137, 137);
    width: 20vw;
    outline-width: 0;
}

@media only screen and (max-width: 600px) {
    .form-control {
        width: 35vw;
    }
}

.mb-3{
    margin-bottom: 1rem;
}

.login-form{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;   
}

.login-card-body > p{    
    position: absolute;
    bottom: 10px;
    font-size: small;
    color: #383838;
}
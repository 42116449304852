.sidebar{
    display: flex;
    flex-direction: column;
    flex: 0.27;
}

.sidebar_header{
    display:flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #f0f2f5;  
    border-right: 1px solid rgb(209, 209, 209);
}

.sidebar_headerRight{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 10vw;
}

.sidebar_headerRight > .MuiSvgIcon-root{
    margin-right: 2vw;
    font-size: 24px !important;
}

.sidebar_search{
    display: flex;
    align-items: center;
    background-color: #F4F9F9;
    height: 39px;
    padding: 10px;
    border-right: 1px solid rgb(243, 243, 243);
    border-bottom: 1px solid #e9e9e9;
}

.sidebar_searchContainer{
    display: flex;
    align-items: center;
    background-color: #f0f2f5;     
    width: 100%;
    height: 35px;
    border-radius: 6px;
    opacity: 0.9;
}

.sidebar_searchContainer > .MuiSvgIcon-root {
    color: rgb(99, 99, 99);
    padding: 10px;
    font-size: 20px !important;
}

.sidebar_searchContainer > input {
    border:none;
    outline-width: 0;
    width: 100%;
    background-color: #f0f2f5;    
    margin-left: 10px;
}

.sidebar_chats{
    flex: 1;
    background-color: #F4F9F9;
    overflow-y: scroll;
    border-right: 1px solid rgb(243, 243, 243);
}

.noconv{
    background-color: #F4F9F9;
    padding: 20px 15px;
}